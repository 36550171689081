import { bearing as TurfBearing, point as TurfPoint, point, distance, destination } from 'turf';
import geomagnetism from '@cristianob/geomagnetism';

const geomagnetismModel = geomagnetism.model();

export const kmToNm = meters => {
  return meters / 1.852;
};

export const magneticBearing = (start, end) => {
  const startPoint = TurfPoint([start[1], start[0]]);
  const endPoint = TurfPoint([end[1], end[0]]);

  const bearing = TurfBearing(startPoint, endPoint);
  const declination = geomagnetismModel.point(start);

  let bearingMag = Math.round(bearing - declination.decl);

  if (bearingMag === 0) {
    bearingMag = 360;
  } else if (bearingMag < 0) {
    bearingMag = 360 + bearingMag;
  }

  if (bearingMag >= 10 && bearingMag < 100) {
    bearingMag = `0${bearingMag}`;
  } else if (bearingMag < 10) {
    bearingMag = `00${bearingMag}`;
  }

  return `${bearingMag}°mag`;
};


export const pythagoreanDistance = (x1, y1, x2, y2) => {
  return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
};

export const fakePythagoreanDistance = (x1, y1, x2, y2) => {
  const dx = x2 - x1;
  const dy = y2 - y1;
  return (Math.abs(dx + dy) + Math.abs(dx - dy)) / 2;
};

export const splitLine = (start, end, length, index) => {
  const startPoint = TurfPoint([start[1], start[0]]);
  const endPoint = TurfPoint([end[1], end[0]]);

  const dist = distance(startPoint, endPoint);
  const heading = TurfBearing(startPoint, endPoint);

  const splitPoint = destination(startPoint, (dist / length) * index, heading);

  return [splitPoint.geometry.coordinates[1], splitPoint.geometry.coordinates[0]];
}

export const decimalToDMS = (D, lng) => {
  let dir;
  if (D === -0) dir = lng ? 'W' : 'S';
  else dir = D < 0 ? (lng ? 'W' : 'S') : lng ? 'E' : 'N';

  D = Math.abs(D);

  let deg = Math.floor(D);

  const minFloat = (D - deg) * 60.0000000001;
  const min = Math.floor(minFloat);

  const sec = Math.round((minFloat - min) * 60);

  if (deg < 0) deg = -deg;

  const pad = (number, size) => {
    let s = String(number);
    while (s.length < (size || 2)) {
      s = `0${s}`;
    }
    return s;
  };

  return `${pad(deg, lng ? 3 : 2)}${pad(min, 2)}${pad(Math.round(sec), 2)}${dir}`;
};

export const DMSToDecimal = D => {
  let lat = 0;

  if (D[1].length === 1) lat += parseInt(D[1], 10) * 10;
  else lat += parseInt(D[1], 10);

  if (D[2]) {
    if (D[2].length === 1) lat += parseInt(D[2], 10) / 6;
    else lat += parseInt(D[2], 10) / 60;
  }

  if (D[3]) {
    if (D[3].length === 1) lat += parseInt(D[3], 10) / 360;
    else lat += parseInt(D[3], 10) / 3600;
  }

  if (!D[4]) lat = -lat;
  else if (D[4] === 'S' || D[4] === 's') lat = -lat;

  let lng = 0;
  if (D[5]) {
    if (D[5].length === 1) lng += parseInt(D[5], 10) * 10;
    else lng += parseInt(D[5], 10);
  }

  if (D[6]) {
    if (D[6].length === 1) lng += parseInt(D[6], 10) / 6;
    else lng += parseInt(D[6], 10) / 60;
  }

  if (D[7]) {
    if (D[7].length === 1) lng += parseInt(D[7], 10) / 360;
    else lng += parseInt(D[7], 10) / 3600;
  }

  if (!D[8]) lng = -lng;
  else if (D[8] === 'W' || D[8] === 'O' || D[8] === 'w' || D[8] === 'o') lng = -lng;

  return { lat, lng };
};

export const DMSToDecimal2 = D => {
  let lat = 0;
  lat += parseInt(D[2], 10);
  lat += parseInt(D[3], 10) / 60;
  lat += parseInt(D[4], 10) / 3600;
  if (D[1] === 'S') lat = -lat;

  let lng = 0;
  lng += parseInt(D[6], 10);
  lng += parseInt(D[7], 10) / 60;
  lng += parseInt(D[8], 10) / 3600;
  if (D[5] === 'W' || D[8] === 'O') lng = -lng;

  return { lat, lng };
};

export const distanceNM = (start, end) => {
  const startPoint = point([start[1], start[0]]);
  const endPoint = point([end[1], end[0]]);
  return kmToNm(distance(startPoint, endPoint)).toFixed(1);
};

export const distanceNMFloat = (start, end) => {
  const startPoint = point([start[1], start[0]]);
  const endPoint = point([end[1], end[0]]);
  return kmToNm(distance(startPoint, endPoint));
};

export const flightTime = (distance, speed) => {
  if (speed > 0) {
    return distance / speed;
  }
  return 0;
};

export const distanceFloat = (start, end) => {
  const startPoint = point([start[1], start[0]]);
  const endPoint = point([end[1], end[0]]);
  return distance(startPoint, endPoint);
};

export const distToSegment = (pI, vI, wI) => {
  const p = { x: pI[1], y: pI[0] };
  const v = { x: vI[1], y: vI[0] };
  const w = { x: wI[1], y: wI[0] };

  const sqr = x => {
    return x * x;
  };

  const dist2 = (v, w) => {
    return sqr(v.x - w.x) + sqr(v.y - w.y);
  };

  const l2 = dist2(v, w);
  if (l2 === 0) return dist2(p, v);
  let t = ((p.x - v.x) * (w.x - v.x) + (p.y - v.y) * (w.y - v.y)) / l2;
  t = Math.max(0, Math.min(1, t));

  return Math.sqrt(dist2(p, { x: v.x + t * (w.x - v.x), y: v.y + t * (w.y - v.y) }));
};

export const getWaypointNewSequence = (newWaypoint, waypoints) => {
  if (waypoints.length === 1) {
    return 2;
  }

  let distanceMap = [];
  for (let i = 0; i < waypoints.length - 1; i++) {
    distanceMap.push([
      i,
      distToSegment(
        [newWaypoint.latitude, newWaypoint.longitude],
        [waypoints[i].latitude, waypoints[i].longitude],
        [waypoints[i + 1].latitude, waypoints[i + 1].longitude],
      ),
    ]);
  }

  distanceMap = distanceMap.sort((a, b) => a[1] - b[1]);

  const insertIndex = distanceMap[0][0] + 2;

  return insertIndex;
};

export const millisecondsToHourMinute = mili => {
  const minutes = String(Math.floor((mili / (1000 * 60)) % 60));
  const hours = String(Math.floor(mili / (1000 * 60 * 60)));

  return `${hours.length < 2 ? `0${hours}` : hours}:${`0${minutes}`.slice(-2)}`;
};
